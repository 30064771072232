@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.resolve-boot-camp {
  margin-bottom: 200px; // NOTE: Custom margin to avoid content being hidden by sticky buttons

  &__header {
    background: light.$background-secondary-default;
    padding: spacing.$l 0 spacing.$xxs 0;
    margin-bottom: spacing.$l;
  }

  &__title {
    color: light.$on-surface-primary-default;
    margin-bottom: spacing.$xs;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
